import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-337635e6"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["disabled"]
const _hoisted_2 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_log_entries = _resolveComponent("log-entries")!
  const _component_native_dialog = _resolveComponent("native-dialog")!
  const _component_app_bar = _resolveComponent("app-bar")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_log_entries, {
      modelValue: _ctx.focusedIndex,
      "onUpdate:modelValue": [
        _cache[0] || (_cache[0] = ($event: any) => ((_ctx.focusedIndex) = $event)),
        _cache[1] || (_cache[1] = ($event: any) => (_ctx.flushPendingChanges()))
      ]
    }, null, 8, ["modelValue"]),
    _createVNode(_component_app_bar, null, {
      default: _withCtx(() => [
        _createElementVNode("button", {
          class: "emoji-fab-button top left",
          onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.isDialogOpen = !_ctx.isDialogOpen)),
          title: "Open this document menu"
        }, _toDisplayString(_ctx.partitionId), 1),
        _createElementVNode("button", {
          class: "emoji-fab-button top left-next",
          title: "Add a plain note to this document",
          onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.addPlainNote()))
        }, " ➕ "),
        _createVNode(_component_native_dialog, {
          modelValue: _ctx.isDialogOpen,
          "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.isDialogOpen) = $event))
        }, {
          default: _withCtx(() => [
            _createElementVNode("button", {
              class: "nav-menu-button",
              onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.handleOpenClick(true))),
              title: "Merge an existing document into this one"
            }, " 📂 Load "),
            _createElementVNode("button", {
              class: _normalizeClass(`nav-menu-button  ${
          !_ctx.isSaveSyncing && _ctx.hasChangesToBackup ? 'bounce' : ''
        }`),
              onClick: _cache[5] || (_cache[5] = ($event: any) => (_ctx.handleSaveClick($event))),
              disabled: _ctx.isSaveSyncing
            }, _toDisplayString(_ctx.isSaveSyncing ? "🔃 Saving Automatically" : "💾 Backup"), 11, _hoisted_1),
            _createElementVNode("button", {
              class: "nav-menu-button",
              onClick: _cache[6] || (_cache[6] = ($event: any) => (_ctx.handleNewClick($event))),
              title: "Brand new document"
            }, " 🔌Unload "),
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.partitionKeys, (icon) => {
              return (_openBlock(), _createElementBlock("button", {
                class: "emoji-button",
                key: icon,
                onClick: ($event: any) => (_ctx.setPartitionKey(icon))
              }, _toDisplayString(icon), 9, _hoisted_2))
            }), 128))
          ]),
          _: 1
        }, 8, ["modelValue"])
      ]),
      _: 1
    })
  ], 64))
}