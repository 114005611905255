import * as IdbKeyVal from "idb-keyval";
import * as Automerge from 'automerge';

const TO_IDB_KEY = (keyId: string) => `AUTOMERGE_${keyId}`;
const MEMORY_CACHE = new Map<string, Automerge.FreezeObject<any>>();

export const exportAsDataUrl = async <TDocumentType>(doc: Automerge.FreezeObject<TDocumentType>): Promise<string | ArrayBuffer | null> => {
    return new Promise((resolve, reject) => {
        const binaryData = Automerge.save(doc);
        const blob = new Blob([binaryData], { type: 'binary' });
        const reader = new FileReader();
        reader.readAsDataURL(blob);
        reader.onloadend = function () {
            const base64data = reader.result;
            resolve(base64data);
        }
        reader.onerror = function (err) {
            reject(err);
        };
    });
}

export const importFromDataUrl = async <TDocumentType>(dataurl: string): Promise<Automerge.FreezeObject<TDocumentType>> => {
    return new Promise((resolve, reject) => {
        const arr = dataurl.split(',');
        if (!arr || arr.length < 2) {
            reject(new Error('Unable to parse dataurl'));
            return;
        }
        const bstr = atob(arr[1]);
        let n = bstr.length;
        const u8arr = new Uint8Array(n);
        while (n--) {
            u8arr[n] = bstr.charCodeAt(n);
        }
        resolve(Automerge.load(u8arr as Automerge.BinaryDocument));
    });
}

export const get = async <TDocumentType>(keyId: string): Promise<Automerge.FreezeObject<TDocumentType> | null> => {
    const cachedValue = MEMORY_CACHE.get(keyId);
    if (cachedValue) return cachedValue;
    const idbKey = TO_IDB_KEY(keyId);
    const data: Uint8Array | null | undefined = await IdbKeyVal.get(idbKey);
    if (!data) return null;
    return Automerge.load<TDocumentType>(data as Automerge.BinaryDocument);
};

export const set = async <TDocumentType>(keyId: string, document: Automerge.FreezeObject<TDocumentType>): Promise<void> => {
    const binaryDocument = Automerge.save(document);
    const idbKey = TO_IDB_KEY(keyId);
    await IdbKeyVal.set(idbKey, binaryDocument);
    MEMORY_CACHE.set(keyId, document);
};

export const change = async <TDocumentType>(keyId: string, fn: Automerge.ChangeFn<TDocumentType>, initFn: () => Promise<Automerge.FreezeObject<TDocumentType>>, message: string | undefined = undefined): Promise<Automerge.FreezeObject<TDocumentType>> => {
    let document = await get<TDocumentType>(keyId);
    if (!document) document = await initFn();
    const newDocument = Automerge.change(document, { message }, fn);
    await set(keyId, newDocument);
    return newDocument;
};

export const del = async (keyId: string): Promise<void> => {
    await IdbKeyVal.del(TO_IDB_KEY(keyId));
    MEMORY_CACHE.delete(keyId);
}