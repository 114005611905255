
import LogEntries from "@/components/LogEntries.vue";
import AppBar from "@/components/AppBar.vue";
import { defineComponent, ref } from "vue";
import { storeToRefs } from "pinia";
import NotesStore from "@/stores/NotesStore";
import NativeDialog from "@/components/NativeDialog.vue";

export default defineComponent({
  name: "HomeView",
  components: { LogEntries, AppBar, NativeDialog },
  setup() {
    const noteStore = NotesStore();
    const {
      notes,
      isSaveSyncing,
      isLoadSyncing,
      hasChangesToBackup,
      partitionId,
    } = storeToRefs(noteStore);
    const {
      init,
      addPlainNote,
      flushPendingChanges,
      changePartition,
      resetAndInit,
      loadFile,
      saveFile,
    } = noteStore;
    const focusedIndex = ref(0);
    const isDialogOpen = ref(false);

    const setBackgroundImage = (key: string) => {
      const bodyElement = document.querySelector("body");
      if (!bodyElement) return;
      bodyElement.style.backgroundImage = `url("data:image/svg+xml,<svg xmlns=%22http://www.w3.org/2000/svg%22 viewBox=%220 0 100 100%22><foreignObject width=%22100px%22 height=%22100px%22><div xmlns=%22http://www.w3.org/1999/xhtml%22 style=%22filter:grayscale(100%);opacity:0.1;font-size:70px;transform: rotate(45deg)%22>${key}</div></foreignObject></svg>")`;
    };
    setBackgroundImage(partitionId.value);
    const setPartitionKey = async (key: string) => {
      setBackgroundImage(key);
      isDialogOpen.value = false;
      focusedIndex.value = -1;
      await changePartition(key);
      focusedIndex.value = 0;
    };
    const handleNewClick = async () => {
      focusedIndex.value = -1;
      await resetAndInit();
      focusedIndex.value = 0;
    };
    const handleOpenClick = async (merge: boolean) => {
      focusedIndex.value = -1;
      await loadFile(merge);
      focusedIndex.value = 0;
    };
    const handleSaveClick = async () => {
      await saveFile();
    };
    init().then(() => {
      focusedIndex.value = 0;
    });
    window.onbeforeunload = function () {
      flushPendingChanges();
    };
    setInterval(() => {
      flushPendingChanges();
    }, 2000);
    return {
      entries: notes,
      isSaveSyncing,
      isLoadSyncing,
      hasChangesToBackup,
      focusedIndex,
      isDialogOpen,
      partitionId,
      partitionKeys: [
        "🤔",
        "👏",
        "💯",
        "💀",
        "🍔",
        "🏆",
        "⏰",
        "📆",
        "🏢",
        "🎲",
        "✍",
        "🐵",
        "😂",
        "👶",
        "🕐",
        "💰",
        "🛒",
        "🐱",
        "🐶",
        "👽",
        "🎓",
        "💎",
        "😍",
        "🔒",
        "🗿",
        "🎮",
        "🎅",
        "🎊",
        "🍷",
        "🎁",
        "🏠",
        "👑",
        "💩",
        "📝",
        "🔞",
        "🕵",
        "🎵",
        "💒",
        "🎬",
        "💲",
      ],
      handleNewClick,
      handleOpenClick,
      handleSaveClick,
      flushPendingChanges,
      setPartitionKey,
      async addPlainNote() {
        focusedIndex.value = -1;
        await addPlainNote(0, "");
        focusedIndex.value = 0;
      },
    };
  },
});
