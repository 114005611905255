
import { defineComponent } from "vue";
import dialogPolyfill from "dialog-polyfill";

export default defineComponent({
  props: { modelValue: Boolean },
  mounted() {
    dialogPolyfill.registerDialog(this.$el);
  },
});
