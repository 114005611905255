import { defineStore } from 'pinia';
import * as Automerge from 'automerge';
import {
    load,
    save,
    get,
    change,
    reset,
    startSaveSync,
    stopSaveSync,
    isSaveSyncing,
    hasChangesToBackup,
    INote,
    INotesDocument
} from "@/databases/IdbNotes";

export interface INoteViewModel extends INote {
    id: string;
    solid: boolean;
}

export interface INotesViewModel {
    partitionId: string;
    pendingEdits: { [id: Automerge.UUID]: string };
    pendingAdds: { [id: Automerge.UUID]: INoteViewModel };
    notes: INoteViewModel[];
    hasChangesToBackup: boolean;
    isSaveSyncing: boolean;
    isLoadSyncing: boolean;
}

const noteToView = (note: Automerge.FreezeObject<INote> & Automerge.TableRow): INoteViewModel => ({
    id: note.id,
    content: note.content,
    rankRating: note.rankRating,
    type: note.type,
    solid: true
});

const noteToModel = (note: INoteViewModel): INote => ({
    content: note.content,
    rankRating: note.rankRating,
    type: note.type
});

const lastSeenPartitionId = localStorage.getItem('lastSeenPartitionKey') || '🤔';

export default defineStore('Notes', {
    state: (): INotesViewModel => {
        return {
            partitionId: lastSeenPartitionId,
            pendingEdits: {},
            pendingAdds: {},
            notes: [],
            hasChangesToBackup: hasChangesToBackup(lastSeenPartitionId),
            isSaveSyncing: false,
            isLoadSyncing: false
        };
    },
    actions: {
        async init() {
            let d = await get(this.partitionId);
            if (!d || !d.notes) {
                d = await change(this.partitionId, d => {
                    if (!d.notes) d.notes = new Automerge.Table<INote>();
                });
            }
            this.notes = d.notes.rows.sort((a, b) => b.rankRating - a.rankRating).filter(r => r.content.length > 0).map(r => noteToView(r));
            this.isSaveSyncing = isSaveSyncing(this.partitionId);
            if (this.notes.length === 0 ||
                !this.notes.find(i => i.content === "")) {
                this.addPlainNote(0, "");
            }
        },
        async changePartition(partitionId: string) {
            await this.flushPendingChanges();
            if (this.isSaveSyncing) {
                stopSaveSync(this.partitionId);
                this.isSaveSyncing = false;
            }
            this.partitionId = partitionId;
            localStorage.setItem('lastSeenPartitionKey', partitionId);
            await this.init();
            this.hasChangesToBackup = hasChangesToBackup(this.partitionId);
        },
        async resetAndInit(): Promise<void> {
            await reset(this.partitionId);
            await this.init();
            if (this.isSaveSyncing) {
                stopSaveSync(this.partitionId);
                this.isSaveSyncing = false;
            }
            this.hasChangesToBackup = hasChangesToBackup(this.partitionId);
        },
        async loadFile(merge: boolean): Promise<void> {
            await load(this.partitionId, (oldDoc, newDoc) => {
                if (merge) {
                    try {
                        return Automerge.merge<INotesDocument>(oldDoc, newDoc);
                    } catch (err) {
                        if (window.confirm('There was an issue merging this document. Do you want to load it instead?')) {
                            return newDoc;
                        } else {
                            return oldDoc;
                        }
                    }
                } else {
                    return newDoc;
                }
            });
            await this.init();
            this.hasChangesToBackup = hasChangesToBackup(this.partitionId);
        },
        async saveFile(): Promise<void> {
            await this.flushPendingChanges();
            const handler = await save(this.partitionId);
            if (handler) {
                startSaveSync(this.partitionId);
                this.isSaveSyncing = isSaveSyncing(this.partitionId);
            }
            this.hasChangesToBackup = hasChangesToBackup(this.partitionId);
        },
        async addPlainNote(index: number, text: string): Promise<void> {
            const newNote = {
                id: Automerge.uuid(),
                content: text,
                type: 'text/plain',
                rankRating: (new Date()).getTime(),
                solid: false
            };
            this.notes.splice(index, 0, newNote);
            this.pendingAdds[newNote.id] = newNote;
            this.hasChangesToBackup = hasChangesToBackup(this.partitionId);
        },
        async dropNote(id: Automerge.UUID): Promise<void> {
            await change(this.partitionId, d => {
                d.notes.remove(id);
            });
            const indexOf = this.notes.findIndex(i => i.id == id);
            if (indexOf === -1) return;
            this.notes.splice(indexOf, 1);
        },
        editPlainNote(id: Automerge.UUID, text: string) {
            const note = this.notes.find(i => i.id === id);
            if (!note) return;
            note.content = text;
            if (note.solid) {
                this.pendingEdits[note.id] = text;
            } else {
                this.pendingAdds[note.id].content = text;
            }
            this.hasChangesToBackup = hasChangesToBackup(this.partitionId);
        },
        async flushPendingChanges() {
            const editEntries = Object.entries(this.pendingEdits);
            const addEntries = Object.entries(this.pendingAdds);
            const editCount = editEntries.length;
            const hasAdds = addEntries.findIndex(([_, i]) => !!i.content) != -1;
            if (editCount === 0 && !hasAdds) {
                return;
            }
            const dontAdds: { [id: string]: INoteViewModel } = {};
            await change(this.partitionId, d => {
                for (const [id, newText] of editEntries) {
                    const x = d.notes.byId(id);
                    if (x) {
                        x.content = newText;
                    }
                }
                for (const [, newEntry] of addEntries) {
                    if (newEntry.content) {
                        newEntry.id = d.notes.add(noteToModel(newEntry));
                        newEntry.solid = true;
                    } else {
                        dontAdds[newEntry.id] = newEntry;
                    }
                }
            });
            this.pendingEdits = {};
            this.pendingAdds = { ...dontAdds };
            this.hasChangesToBackup = hasChangesToBackup(this.partitionId);
        }
    }
});