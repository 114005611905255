import { fileOpen, fileSave, CoreFileOptions, WellKnownDirectory, FileWithHandle } from "browser-fs-access";
import { get, set, del } from "idb-keyval";

const TO_IDB_KEY_SAVE = (fileId: string) => `SAVE_${fileId}`;
const SAVE_MEMORY_CACHE = new Map<string, FileSystemFileHandle>();

export const open = async (fileId: string, startIn: WellKnownDirectory, options: CoreFileOptions): Promise<FileWithHandle | null> => {
    return await fileOpen({
        multiple: false,
        startIn,
        ...options,
        id: fileId
    }) || null;
};

export const save = async (fileId: string, fileName: string, startIn: WellKnownDirectory, options: CoreFileOptions, data: Blob | Promise<Blob> | Response): Promise<FileSystemFileHandle | null> => {
    let existing = SAVE_MEMORY_CACHE.get(fileId);
    if (!existing) {
        const dbKey = TO_IDB_KEY_SAVE(fileId);
        existing = await get(dbKey);
    }
    const fh = await fileSave(data, {
        startIn,
        fileName,
        ...options,
        id: fileId
    }, existing || null);
    if (!fh) return null;
    const dbKey = TO_IDB_KEY_SAVE(fileId)
    await set(dbKey, fh);
    SAVE_MEMORY_CACHE.set(fileId, fh);
    return fh || null;
};

export const clear = async (fileId: string): Promise<void> => {
    const saveDbKey = TO_IDB_KEY_SAVE(fileId);
    await del(saveDbKey);
    SAVE_MEMORY_CACHE.delete(fileId);
};