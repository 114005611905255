import * as Automerge from 'automerge';
import * as IdbAutomerge from '@/databases/IdbAutomerge';
import * as IdbFiles from '@/databases/IdbFiles';
import { FileWithHandle, WellKnownDirectory } from 'browser-fs-access';

export const importFromDataUrl = IdbAutomerge.importFromDataUrl;
export const exportAsDataUrl = IdbAutomerge.exportAsDataUrl;

export const DEFAULT_INTERVAL = 3000;

export interface ISyncContext {
    automergeKey: string;
    defaultFileName: string;
    defaultDescription: string;
    defaultStartIn: WellKnownDirectory;
    currentSaveSyncId: number | null;
    changesToBackup: boolean;
    fileId: string | null;
}

export const get = async <TModel>(ctx: ISyncContext): Promise<Automerge.FreezeObject<TModel> | null> => {
    return await IdbAutomerge.get<TModel>(ctx.automergeKey);
};

export const set = async <TModel>(ctx: ISyncContext, doc: Automerge.FreezeObject<TModel>): Promise<void> => {
    await IdbAutomerge.set(ctx.automergeKey, doc);
};

export const change = async <TModel>(ctx: ISyncContext, fn: Automerge.ChangeFn<TModel>, initFn: () => Promise<Automerge.FreezeObject<TModel>>, message: string | undefined = undefined): Promise<Automerge.FreezeObject<TModel>> => {
    const result = await IdbAutomerge.change(ctx.automergeKey, fn, initFn, message);
    ctx.changesToBackup = true;
    return result;
};
export const save = async <TModel>(ctx: ISyncContext): Promise<FileSystemFileHandle | null> => {
    const doc = await get<TModel>(ctx);
    if (!doc) return null;
    if (!ctx.fileId) ctx.fileId = Automerge.uuid();
    const output = new Blob([Automerge.save(doc)]);
    const handle = await IdbFiles.save(ctx.fileId, `${ctx.defaultFileName}.automerge`, ctx.defaultStartIn, {
        extensions: [".automerge"],
        description: ctx.defaultDescription,
        mimeTypes: ["text/plain"]
    }, output);
    ctx.changesToBackup = false;
    return handle;
};

export type MergeDocumentFunction<TModel> = (oldDoc: Automerge.FreezeObject<TModel>, newDoc: Automerge.FreezeObject<TModel>) => Automerge.FreezeObject<TModel>;

export const load = async <TModel>(ctx: ISyncContext, mergeFn: MergeDocumentFunction<TModel>): Promise<FileWithHandle | null> => {
    if (!ctx.fileId) ctx.fileId = Automerge.uuid();
    const fileHandler = await IdbFiles.open(ctx.fileId, ctx.defaultStartIn, {
        extensions: [".automerge"],
        description: ctx.defaultDescription,
        mimeTypes: ["text/plain"]
    });
    if (!fileHandler) return null;
    const buffer = await fileHandler.arrayBuffer();
    const array = new Uint8Array(buffer);
    let document = Automerge.load<TModel>(array as Automerge.BinaryDocument);
    const currentDoc = await IdbAutomerge.get<TModel>(ctx.automergeKey);
    if (currentDoc) {
        document = mergeFn(currentDoc, document);
    }
    await IdbAutomerge.set(ctx.automergeKey, document);
    ctx.changesToBackup = false;
    return fileHandler
};

export const reset = async <TModel>(ctx: ISyncContext): Promise<Automerge.FreezeObject<TModel> | null> => {
    stopSaveSync(ctx);
    ctx.fileId = Automerge.uuid();
    const document = Automerge.init<TModel>();
    await Promise.all([
        IdbFiles.clear(ctx.fileId),
        IdbAutomerge.set(ctx.automergeKey, document)
    ]);
    ctx.changesToBackup = false;
    return document;
};

export const startSaveSync = (ctx: ISyncContext, frequencyMs: number | undefined = DEFAULT_INTERVAL): void => {
    ctx.currentSaveSyncId = setInterval(() => {
        save(ctx);
    }, frequencyMs);
};

export const stopSaveSync = (ctx: ISyncContext): void => {
    if (!ctx.currentSaveSyncId) return;
    clearInterval(ctx.currentSaveSyncId);
};