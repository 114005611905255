import { withModifiers as _withModifiers, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-1d9b98fc"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "logentry entering" }
const _hoisted_2 = ["autofocus", "value"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("textarea", {
      autofocus: _ctx.start,
      class: _normalizeClass({ start: _ctx.start, end: _ctx.end, empty: !_ctx.modelValue, logentryTextarea: true }),
      placeholder: "Toss in a new thought 🤔...",
      value: _ctx.modelValue,
      onInput: _cache[0] || (_cache[0] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.handleInput && _ctx.handleInput(...args)), ["stop"])),
      onFocus: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.handleFocus && _ctx.handleFocus(...args))),
      onBlur: _cache[2] || (_cache[2] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.handleBlur && _ctx.handleBlur(...args)), ["stop"]))
    }, null, 42, _hoisted_2)
  ]))
}