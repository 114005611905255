
export default {
  setup() {
    if (navigator.storage && navigator.storage.persist)
      navigator.storage.persist().then(function (persistent) {
        if (persistent)
          console.log(
            "Storage will not be cleared except by explicit user action"
          );
        else
          console.log(
            "Storage may be cleared by the UA under storage pressure."
          );
      });
    try {
      const worker = new Worker(new URL("./background.ts", import.meta.url));
      worker.postMessage({
        question:
          "The Answer to the Ultimate Question of Life, The Universe, and Everything.",
      });
      worker.onmessage = ({ data: { answer } }) => {
        console.log(answer);
      };
    } catch (err) {
      console.error(err);
    }
    return {};
  },
};
