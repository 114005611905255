<template>
  <div class="app-bar">
    <slot></slot>
  </div>
</template>


<style>
.app-bar {
  position: fixed;
  top: 0px;
  padding: 4px 0px 15px 0px;
  width: 100%;
  text-align: left;
  background-image: linear-gradient(
    360deg,
    rgba(255, 255, 255, 0),
    rgb(247 211 234)
  );
}
@media only screen and (max-width: 600px) {
  .app-bar-button {
    font-size: 1em;
    margin: 0 1px 0 1px;
  }
  .app-bar {
    text-align: center;
  }
}
</style>