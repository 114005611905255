
import { defineComponent, nextTick } from "vue";
import { storeToRefs } from "pinia";
import NotesStore from "@/stores/NotesStore";
import LogEntry from "@/components/LogEntry.vue";

export default defineComponent({
  components: { LogEntry },
  props: { modelValue: Number },
  methods: {
    focusOnElementWithIndex(i: number) {
      const childElement = (this.$el as HTMLElement).querySelector(
        `.container:nth-child(${i + 1}) textarea`
      ) as HTMLElement | null;
      if (!childElement) return;
      childElement.focus();
    },
    handleBlur($event: FocusEvent, i: number) {
      const newElement = $event.target as HTMLElement;
      if (!newElement.classList.contains("logentryTextarea")) {
        this.focusOnElementWithIndex(i);
      }
    },
  },
  watch: {
    modelValue: {
      handler(i) {
        if (i < 0) return;
        if (!this.$el) return;
        this.focusOnElementWithIndex(i);
      },
    },
  },
  mounted() {
    this.focusOnElementWithIndex(0);
  },
  setup(_, { emit }) {
    const noteStore = NotesStore();
    const { notes } = storeToRefs(noteStore);
    const { editPlainNote, addPlainNote } = noteStore;
    let lastFocusedElement: HTMLElement | null = null;
    const handleFocus = async ($event: FocusEvent, i: number) => {
      const newElement = $event.target as HTMLElement;
      if (lastFocusedElement && lastFocusedElement != newElement) {
        lastFocusedElement.classList.remove("focused");
      }
      lastFocusedElement = newElement;
      lastFocusedElement.classList.add("focused");
      emit("update:modelValue", i);
    };
    const handleFieldInput = async (val: string, id: string, index: number) => {
      if (notes.value.length === 0) return;
      const noteAtIndex = notes.value[index];
      if (!noteAtIndex) return;
      editPlainNote(id, val);
      if (notes.value.find((i) => !i.content.length)) return;
      await addPlainNote(index + 1, "");
      nextTick(() => {
        window.scrollTo({
          left: 0,
          top: document.body.scrollHeight,
          behavior: "smooth",
        });
      });
    };
    return { handleFocus, handleFieldInput, notes };
  },
});
