
import { defineComponent } from "vue";

export default defineComponent({
  name: "LogEntry",
  props: {
    modelValue: String,
    start: Boolean,
    end: Boolean,
  },
  mounted() {
    this.$nextTick(() => {
      this.$el.classList.remove("entering");
    });
  },
  setup(_, { emit }) {
    const heightAnimationDuration = 100; // 0.1s;
    const scrollIntoView = ($event: FocusEvent) => {
      ($event.target as HTMLElement).scrollIntoView({
        behavior: "smooth",
        block: "nearest",
        inline: "center",
      });
      setTimeout(() => {
        // Re-focus again after height animation 
        ($event.target as HTMLElement).scrollIntoView({
          behavior: "smooth",
          block: "nearest",
          inline: "center",
        });
      }, heightAnimationDuration);
    };
    const handleFocus = ($event: FocusEvent) => {
      scrollIntoView($event);
      emit("focus", $event);
    };
    const handleBlur = ($event: FocusEvent) => {
      if (!document.hasFocus()) {
        ($event.target as HTMLElement).classList.add("focused");
      } else if (!$event.relatedTarget) {
        ($event.target as HTMLElement).focus();
      } else {
        emit("blur", $event);
      }
    };
    const handleInput = ($event: InputEvent) => {
      const textAreaText = ($event.target as HTMLTextAreaElement).value;
      emit("update:modelValue", textAreaText);
    };
    return { handleFocus, handleBlur, handleInput };
  },
});
