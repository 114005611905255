import { createApp } from 'vue'
import App from './App.vue'
import 'milligram/dist/milligram.min.css';
import 'dialog-polyfill/dist/dialog-polyfill.css';
import './registerServiceWorker'
import router from './router'
import { createPinia } from 'pinia';

createApp(App)
    .use(router)
    .use(createPinia())
    .mount('#app')
