import * as Automerge from 'automerge';
import * as AutomergeFiles from '@/databases/IdbAutomergeFiles';
import { FileWithHandle } from 'browser-fs-access';

export interface INote {
    rankRating: number;
    content: string;
    type: string; // text/plain, text/markdown
}

export interface INotesDocument {
    notes: Automerge.Table<INote>;
}

const UNIVERSE = 'data:binary;base64,hW9Kg+HP+hQAhQEBEB09UCQ+LEfxlCvNRKj5BtcBoCb0AuNyK0AuNLX4WQPAEVr3yi4ECTE47073HhU9pPsHAQIDAhMCIwY1EEACVgIHFQchAiMCNAFCAlYCgAECfwB/AX8Bf6OR3JQGfw5Jbml0aWFsaXphdGlvbn8Afwd/BW5vdGVzfwB/AQF/Bn8AfwAA';
const partitionContexts = new Map<string, AutomergeFiles.ISyncContext>();

const getPartitionContext = (partitionId: string) => {
    let existing = partitionContexts.get(partitionId);
    if (!existing) {
        existing = {
            automergeKey: `Notes${partitionId}`,
            changesToBackup: false,
            currentSaveSyncId: null,
            fileId: Automerge.uuid(),
            defaultFileName: 'notes',
            defaultDescription: "A collaborative notes file",
            defaultStartIn: 'documents'
        };
        partitionContexts.set(partitionId, existing);
    }
    return existing;
}

export const get = async (partitionId: string): Promise<Automerge.FreezeObject<INotesDocument> | null> => {
    const context = getPartitionContext(partitionId);
    return await AutomergeFiles.get<INotesDocument>(context);
};

export const set = async (partitionId: string, doc: Automerge.FreezeObject<INotesDocument>): Promise<void> => {
    const context = getPartitionContext(partitionId);
    await AutomergeFiles.set(context, doc);
};

export const hasChangesToBackup = (partitionId: string) => getPartitionContext(partitionId).changesToBackup;

export const change = async (partitionId: string, fn: Automerge.ChangeFn<INotesDocument>, message: string | undefined = undefined): Promise<Automerge.FreezeObject<INotesDocument>> => {
    const context = getPartitionContext(partitionId);
    return await AutomergeFiles.change(context, fn, () => AutomergeFiles.importFromDataUrl(UNIVERSE), message);
};

export const save = async (partitionId: string): Promise<FileSystemFileHandle | null> => {
    const context = getPartitionContext(partitionId);
    return await AutomergeFiles.save<INotesDocument>(context);
};
export const load = async (partitionId: string, mergeFn: AutomergeFiles.MergeDocumentFunction<INotesDocument>): Promise<FileWithHandle | null> => {
    const context = getPartitionContext(partitionId);
    return await AutomergeFiles.load<INotesDocument>(context, mergeFn);
};

export const reset = async (partitionId: string): Promise<Automerge.FreezeObject<INotesDocument> | null> => {
    const context = getPartitionContext(partitionId);
    return await AutomergeFiles.reset(context);
};

export const isSaveSyncing = (partitionId: string): boolean => getPartitionContext(partitionId).currentSaveSyncId !== null;

export const startSaveSync = (partitionId: string, frequencyMs: number | undefined = AutomergeFiles.DEFAULT_INTERVAL): void => {
    const context = getPartitionContext(partitionId);
    AutomergeFiles.startSaveSync(context, frequencyMs);
};

export const stopSaveSync = (partitionId: string): void => {
    const context = getPartitionContext(partitionId);
    AutomergeFiles.stopSaveSync(context);
};