import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_log_entry = _resolveComponent("log-entry")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.notes, (entry, i) => {
      return (_openBlock(), _createBlock(_component_log_entry, {
        key: i,
        modelValue: entry.content,
        "onUpdate:modelValue": [($event: any) => ((entry.content) = $event), ($event: any) => (_ctx.handleFieldInput($event, entry.id, i))],
        onFocus: ($event: any) => (_ctx.handleFocus($event, i)),
        onBlur: ($event: any) => (_ctx.handleBlur($event, i)),
        start: i === 0,
        end: i === _ctx.notes.length - 1
      }, null, 8, ["modelValue", "onUpdate:modelValue", "onFocus", "onBlur", "start", "end"]))
    }), 128))
  ]))
}